'use client';

import React, { useMemo, useState } from 'react';
import type { Currency } from '@whoop/i18n/types/internationalization';
import type {
  AnalyticsFunction,
  MediaItem,
  Optional,
  ProductItem,
  ProductNode,
} from '../../types';
import {
  getDescriptionFromSelection,
  getEngravingTypesFromSelection,
  getGenderFromSelection,
  getMediaFromSelection,
  getSizingGuideFromSelection,
} from '../../utils/legacyProductHelpers';
import type { ProductDetailsGridProps } from '../ProductsDetailsGrid/ProductsDetailsGrid';
import { ProductDetailsGrid } from '../ProductsDetailsGrid/ProductsDetailsGrid';
import { MultiProductSelect } from '../ProductMultiSelect/MultiProductSelect';
import { ProductMedia } from '../ProductMedia/ProductMedia';
import { PreloadedImages } from '../PreloadedImages/PreloadedImages';
import {
  getEngravingError,
  type EngravingInfo,
} from '../../utils/engravingUtils';
import {
  isEngravingImageForType,
  matchEngravingPreviewImage,
} from '../../utils/engravingPreviewUtils';
import {
  EngravingPreview,
  EngravingSelector,
} from '../Accessories/EngravingSelector/EngravingSelector';
import { SizingGuide } from '../Accessories/SizingGuide/SizingGuide';

export interface ProductDetailsProps
  extends Omit<ProductDetailsGridProps, 'onChange' | 'media'> {
  currency: Currency;
  node: ProductNode;
  value?: ProductItem;
  onChange?: (selection: ProductItem, engraving?: EngravingInfo) => void;
  onAnalyticsEvent?: AnalyticsFunction;
  specialSale?: boolean;
  engravingValue?: EngravingInfo;
  showEngravingError?: boolean;
}

export function ProductDetails({
  currency,
  node,
  children,
  onChange,
  value,
  onAnalyticsEvent,
  specialSale,
  engravingValue,
  showEngravingError,
  ...props
}: ProductDetailsProps): JSX.Element {
  const [selection, _setSelection] = useState<ProductItem>();
  const description = getDescriptionFromSelection(selection, node);
  const setSelection = (item: ProductItem): void => {
    _setSelection(item);
    if (onChange) {
      onChange(item);
    }
  };

  const engravingTypes = getEngravingTypesFromSelection(selection, node);
  const engravingError = engravingValue
    ? getEngravingError(engravingValue)
    : '';
  const setEngraving = (newValue?: EngravingInfo) => {
    if (onChange && selection) {
      onChange(selection, newValue);
    }
  };

  const sizingGuide = getSizingGuideFromSelection(selection, node);
  const gender = getGenderFromSelection(selection, node);

  const media = useMemo(() => {
    let media: Optional<MediaItem[]> = [];

    if (selection) {
      media = getMediaFromSelection(selection, node);

      if (engravingTypes?.length) {
        const type = engravingValue?.type || engravingTypes[0];
        media = media?.filter((media) =>
          isEngravingImageForType(media.url, type),
        );
      }
    }

    return media;
  }, [engravingValue, node, selection]);

  const mediaUrls = useMemo(() => {
    return media?.map((item) => item.url) ?? [];
  }, [media]);

  return (
    <>
      <PreloadedImages images={mediaUrls} />
      <ProductDetailsGrid
        description={description}
        media={media?.map((item) => {
          if (matchEngravingPreviewImage(item.url) && engravingValue) {
            return (
              <EngravingPreview
                engraving={engravingValue}
                key={item.id}
                media={item}
              />
            );
          }
          return <ProductMedia {...item} key={item.id} />;
        })}
        onAnalyticsEvent={onAnalyticsEvent}
        onImageChange={(index: number) => {
          if (onAnalyticsEvent) {
            onAnalyticsEvent('Product Details Viewed Image', {
              product_handle: node.product_info.handle,
              image_index: index,
            });
          }
        }}
        {...props}
      >
        <>
          <MultiProductSelect
            node={node}
            onChange={setSelection}
            onOptionSelected={(option, type, item) => {
              if (onAnalyticsEvent) {
                onAnalyticsEvent('Product Details Option Selected', {
                  product: node.product_info.handle,
                  sku: item.sku,
                  option,
                  option_type: type,
                  item,
                });
              }
            }}
            specialSale={specialSale}
            value={value}
          />
          {sizingGuide ? (
            <SizingGuide
              currency={currency}
              gender={gender}
              type={sizingGuide}
            />
          ) : null}
          {Boolean(engravingTypes) && (
            <EngravingSelector
              engravingTypes={engravingTypes ?? []}
              error={showEngravingError ? engravingError : undefined}
              onChange={setEngraving}
              value={engravingValue}
            />
          )}
          {children}
        </>
      </ProductDetailsGrid>
    </>
  );
}
