import React from 'react';
import { clsx } from 'clsx';
import type { BasicComponentProps, MediaItem } from '../../types';

export function ProductMedia({
  url,
  id,
  type,
}: MediaItem & BasicComponentProps) {
  if (type === 'video') {
    return (
      <video autoPlay disableRemotePlayback loop muted playsInline>
        <source src={url} type='video/mp4' />
      </video>
    );
  }
  return (
    <img
      alt={`product-img-${id}`}
      className={clsx(
        'bg-gray-a10 relative h-[400px] max-h-full w-full overflow-hidden text-black',
        '[&_.slideshow_img]:h-full [&_.slideshow_img]:object-cover',
        '[&_.slideshow_video]:h-full [&_.slideshow_video]:object-cover',
        '[&_.img]:h-full [&_.img]:object-cover',
        '[&_.video]:h-full [&_.video]:object-cover',
      )}
      src={url}
    />
  );
}
