'use client';

import React, { useMemo, useState } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import clsx from 'clsx';
import type { MediaItem } from '../../../types';
import { FormInputType } from '../../../types';
import { ProductMedia } from '../../ProductMedia/ProductMedia';
import type {
  EngravingData,
  EngravingIconField,
  EngravingInfo,
  EngravingTextField,
  EngravingType,
} from '../../../utils/engravingUtils';
import {
  completeEngraving,
  ENGRAVING_ACTIVITY_ICONS,
  getEngravingFields,
  getTextEngravingError,
  WHOOP_ID_ENGRAVING_ACTIVITY_ICONS,
} from '../../../utils/engravingUtils';
import {
  defaultTransform,
  engravingImageToTransform,
  getFieldHeight,
  PREVIEW_GAP,
  sumFieldHeights,
  transformToCss,
} from '../../../utils/engravingPreviewUtils';
import Icon from '../../Icon/Icon';
import type { IconTypeVariants } from '../../Icon/Icon.util';
import Input from '../../Input/Input';
import { RadioToggle } from '../../RaddioToggle/RadioToggle';

/**
 * Icon Selector - Input field for selection icons
 * Only purpose is to output a string via onChange that is a valid icon name.
 */
export interface IconSelectorProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  type: EngravingType;
  value: string;
  onChange: (value: string) => void;
}
function IconSelector({
  type,
  value,
  onChange,
  className,
  ...props
}: IconSelectorProps) {
  const { t } = useTranslation('engraving');
  const [selectedIcon, setSelectedIcon] = useState<string>(value);
  const [showSelector, setShowSelector] = useState(true);
  const text = selectedIcon
    ? t('icon', { label: t(`labels.${selectedIcon}`) })
    : t('select-icon');
  const selectIcon = (icon: string) => {
    setSelectedIcon(icon);
    setShowSelector(false);
    onChange(icon);
  };

  return (
    <>
      <div
        className={clsx(
          'max-h-52 overflow-y-auto rounded border text-center text-black/30 transition-all',
          '[&>svg]:float-left [&>svg]:box-content [&>svg]:cursor-pointer [&>svg]:p-3 [&>svg]:text-black/50 [&>svg]:transition-colors',
          className,
        )}
        {...props}
      >
        <button
          className={clsx(
            'flex w-full cursor-pointer items-center justify-between px-4 py-3 text-left text-base font-normal text-black',
            '[&_.selected]:text-black',
            '[&>svg]:h-3 [&>svg]:w-3',
            selectedIcon
              ? '[&>svg]:text-blue-strain'
              : '[&>svg:hover]:text-black',
          )}
          onClick={() =>
            Boolean(selectedIcon) && setShowSelector(!showSelector)
          }
          type='button'
        >
          {text}
          <Icon alt='selector' variant='caret_down' />
        </button>

        <div className='grid grid-cols-6 overflow-x-hidden'>
          {showSelector && type === 'gen4_strap_icon'
            ? ENGRAVING_ACTIVITY_ICONS.map((icon) => (
                <button
                  className={clsx(
                    icon === selectedIcon
                      ? 'text-blue-strain'
                      : 'text-gray-b50',
                    'box-content flex justify-center p-3',
                  )}
                  key={icon}
                  onClick={() => selectIcon(icon)}
                  type='button'
                >
                  <Icon
                    alt={t(`labels.${icon}`)}
                    color='currentColor'
                    variant={icon as IconTypeVariants}
                  />
                </button>
              ))
            : null}
          {showSelector && type === 'gen4_id_icon'
            ? WHOOP_ID_ENGRAVING_ACTIVITY_ICONS.map((icon) => (
                <button
                  className={clsx(
                    icon === selectedIcon
                      ? 'text-blue-strain'
                      : 'text-gray-b50',
                    'box-content p-3',
                  )}
                  key={icon}
                  onClick={() => selectIcon(icon)}
                  type='button'
                >
                  <Icon
                    alt={t(`labels.${icon}`)}
                    color='currentColor'
                    variant={icon as IconTypeVariants}
                  />
                </button>
              ))
            : null}
        </div>
      </div>
      {!showSelector && (
        <div className='!my-3 mx-auto'>
          <Icon
            alt={selectedIcon}
            size='xl'
            variant={selectedIcon as IconTypeVariants}
          />
        </div>
      )}
    </>
  );
}

/**
 * Engraving Form - Form (collection of inputs) based on an EngravingType.
 *
 * Given an engraving type will output a complete EngravingData.
 * Complete means that all values are included even if they are empty.
 * This is necessary since our system cannot handle partial engraving data.
 */
export interface EngravingFormProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  type: EngravingType;
  value?: EngravingData;
  onChange?: (value: EngravingData) => void;
}
export function EngravingForm({
  type,
  value,
  onChange,
  className,
  ...props
}: EngravingFormProps) {
  const fields = useMemo(() => getEngravingFields(type), [type]);
  const updateText = (field: EngravingTextField, newValue: string) => {
    const error = getTextEngravingError(newValue, field.scoreType);
    const newData = completeEngraving(
      type,
      {
        ...value,
        [field.name]: { value: newValue.toUpperCase(), error },
      },
      fields,
    );
    onChange && onChange(newData);
  };

  const updateIcon = (field: EngravingIconField, newValue: string) => {
    const newData = completeEngraving(
      type,
      {
        ...value,
        [field.name]: { value: newValue },
      },
      fields,
    );
    onChange && onChange(newData);
  };

  return (
    <div
      className={clsx(
        'flex min-w-[calc(min(400px,100%))] flex-1 flex-col',
        '[&>div]:mx-2 [&>div]:my-3',
        className,
      )}
      {...props}
    >
      {fields.map((field) => {
        if (field.type === 'text') {
          return (
            <Input
              autoComplete='off'
              errorMessage={value?.[field.name]?.error}
              hasError={Boolean(value?.[field.name]?.error)}
              id={field.label}
              key={`${type}-${field.name}`}
              label={field.label}
              onChange={updateText.bind(null, field)}
              type={FormInputType.TEXT}
              value={value?.[field.name]?.value}
            />
          );
        }
        return (
          <IconSelector
            key={`${type}-${field.name}`}
            onChange={updateIcon.bind(null, field)}
            type={type}
            value={value?.[field.name]?.value ?? ''}
          />
        );
      })}
    </div>
  );
}

/**
 * Engraving Selector - Selector based on a list of engraving types.
 * Given a list of engraving types will only output a single engraving.
 * Can optionally be given a global error to display
 */
export interface EngravingSelectorProps {
  value?: EngravingInfo;
  onChange?: (value: EngravingInfo) => void;
  engravingTypes: EngravingType[];
  error?: string;
}

export function EngravingSelector({
  value,
  onChange,
  engravingTypes,
  error,
}: EngravingSelectorProps) {
  const { t } = useTranslation('engraving');
  const [type, setType] = useState(value?.type || engravingTypes[0]);
  const [engravings, setEngravings] = useState<
    Record<EngravingType, EngravingData>
  >(
    (value ? { [value.type]: value.data } : {}) as Record<
      EngravingType,
      EngravingData
    >,
  );

  const onTypeChange = (newType: EngravingType): void => {
    setType(newType);
    onChange && onChange({ type: newType, data: engravings[newType] });
  };

  const onEngravingsChange = (newEngravings: EngravingData): void => {
    setEngravings({ ...engravings, [type]: newEngravings });
    onChange && onChange({ type, data: newEngravings });
  };

  return (
    <div
      className={clsx(
        '[&>h2]:pt-6 [&>h2]:text-2xl [&>h2]:normal-case [&>h2]:tracking-normal',
        'grid grid-cols-1 gap-4',
      )}
    >
      <h2>{t('engraving-heading')}</h2>

      {engravingTypes.length > 1 && (
        <RadioToggle
          label='engraving-type'
          onChange={onTypeChange}
          options={engravingTypes.map((type) => ({
            value: type,
            label: t(`type.${type}`),
          }))}
          value={type}
        />
      )}
      <EngravingForm
        onChange={onEngravingsChange}
        type={type}
        value={engravings[type]}
      />
      {error ? (
        <div className='text-red-form mx-2.5 text-sm'>{error}</div>
      ) : null}
    </div>
  );
}

/**
 * This can be used in replacement for product media.
 * It takes in a MediaItem and EngravingInfo and renders an overlay of EngravingInfo on top of the MediaItem.
 */
export interface EngravingPreviewProps
  extends React.HTMLAttributes<HTMLDivElement> {
  engraving: EngravingInfo;
  media: MediaItem;
}
export function EngravingPreview({
  engraving,
  media,
  ...props
}: EngravingPreviewProps) {
  const fields = useMemo(
    () => getEngravingFields(engraving.type),
    [engraving.type],
  );
  const transform = useMemo(
    () => engravingImageToTransform(media.url) || defaultTransform(),
    [media.url],
  );
  const totalHeight =
    sumFieldHeights(fields) + Math.max(fields.length - 1, 0) * PREVIEW_GAP;

  return (
    <div className='relative h-full max-h-full' {...props}>
      <ProductMedia {...media} />
      <svg
        className={clsx(
          'absolute left-0 top-0',
          '[&>text]:fill-gray-engravingPreview [&>text]:font-semibold',
        )}
        height='100%'
        preserveAspectRatio='xMidYMid meet'
        style={{ transform: transformToCss(transform) }}
        viewBox='0 0 100 100'
        width='100%'
      >
        {fields.map((field, index, arr) => {
          const fieldsSoFar = arr.slice(0, index + 1);
          const heightSoFar =
            sumFieldHeights(fieldsSoFar) +
            Math.max(fieldsSoFar.length - 1, 0) * PREVIEW_GAP;
          if (engraving.data?.[field.name].error) {
            return;
          }
          if (field.type === 'icon') {
            const icon = engraving.data?.[field.name].value;
            const iconHeight = 30;
            const iconWidth = 30;
            return (
              icon && (
                <svg
                  className='text-gray-engravingPreview'
                  height={iconHeight}
                  key={`${engraving.type}-${field.name}`}
                  width={iconWidth}
                  x={50 - iconWidth / 2}
                  y={50 - iconHeight - totalHeight / 2 + heightSoFar}
                >
                  <Icon
                    alt={icon}
                    color='currentColor'
                    size='medium'
                    variant={icon as IconTypeVariants}
                  />
                </svg>
              )
            );
          }
          return (
            <text
              fontSize={getFieldHeight(field)}
              key={`${engraving.type}-${field.name}`}
              textAnchor='middle'
              x={50}
              y={50 - totalHeight / 2 + heightSoFar}
            >
              {engraving.data?.[field.name].value}
            </text>
          );
        })}
      </svg>
    </div>
  );
}
