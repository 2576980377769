'use client';

interface PreloadedImagesProps {
  images: string[];
}

export function PreloadedImages({ images }: PreloadedImagesProps): JSX.Element {
  return (
    <>
      {images.map((src) => (
        <img alt='' height={0} key={src} src={src} width={0} />
      ))}
    </>
  );
}
