/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ChangeEvent } from 'react';
import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';

type RadioToggleSizes = 'small' | 'medium' | 'large';

const sizeStyles = {
  small: 'text-xs px-6 py-2 leading-3',
  medium: 'text-xs px-8 py-3 leading-3',
  large: 'text-sm px-10 py-4 leading-5',
};

interface RadioToggleOption {
  value: any;
  label: string;
}

export interface RadioToggleProps {
  label: string;
  onChange: (value: any) => void;
  options: RadioToggleOption[];
  useExternalState?: boolean;
  value?: any;
  allowNone?: boolean;
  size?: RadioToggleSizes;
}

export const RadioToggle = ({
  label,
  onChange,
  options,
  useExternalState = false,
  value,
  allowNone = false,
  size = 'medium',
}: RadioToggleProps): JSX.Element | null => {
  const [selectedValue, setSelectedValue] = useState(value);

  // set value when parent updates value
  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    } else if (!allowNone && options.length > 0) {
      setSelectedValue(options[0].value);
    }
  }, [value]);

  if (options.length <= 0) return null;

  const changeValue = (value: any) => {
    if (value === selectedValue) return;
    onChange(value);
    setSelectedValue(value);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as any;
    changeValue(value);
  };

  const actualValue = useExternalState ? value : selectedValue;

  return (
    <div
      aria-label={label}
      className='flex justify-evenly whitespace-nowrap rounded-full border-2 border-gray-500 uppercase'
      role='radiogroup'
    >
      {options.map(({ label, value }, index) => {
        const id = `radio-toggle-${label}-${index}`;
        return (
          <Fragment key={`${label}-${value}`}>
            <input
              aria-label={label}
              checked={value === actualValue}
              className='hidden w-full'
              id={id}
              name={id}
              onChange={handleOnChange}
              type='radio'
              value={value}
            />
            <label
              className={clsx(
                'inline-block w-full cursor-pointer rounded-full text-center font-bold tracking-widest',
                value === actualValue
                  ? '-m-0.5 border-2 border-black text-black'
                  : 'text-gray-500',
                sizeStyles[size],
              )}
              htmlFor={id}
            >
              {label}
            </label>
          </Fragment>
        );
      })}
    </div>
  );
};
