import { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from '@whoop/i18n/lang/client';
import { useMultiProductOptions } from '../../utils/legacyProductHelpers';
import type {
  BasicComponentProps,
  ProductItem,
  ProductNode,
} from '../../types';
import Badge from '../Badge';
import { SwatchOptionSelect } from '../SwatchOptionSelect/SwatchOptionSelect';

const multiProductBadgeStyles = [
  'p-1 whitespace-nowrap uppercase text-xs tracking-[2px] font-semibold w-fit rounded-sm relative m-0 inline bg-black text-white align-bottom',
  '[&_.white]:bg-white [&_.white]:text-black',
  '[&_.pro]:bg-yellow-300 [&_.pro]:text-black',
  '[&_.gray]:bg-gray-a700 [&_.gray]:text-white',
];

const selectedOptionOuterStyles =
  'text-xl font-thin leading-6 tracking-[0.5px] text-left inline';

const swatchOptionsStyles = 'border-gray-40 border-b pb-6';

export type MultiProductProps = {
  node?: ProductNode;
  value?: ProductItem;
  specialSale?: boolean;
  onChange?: (item: ProductItem) => void;
  onOptionSelected?: (option: string, type: string, item: ProductItem) => void;
} & BasicComponentProps;

export function MultiProductSelect({
  node,
  value,
  specialSale,
  onChange,
  onOptionSelected,
}: MultiProductProps): JSX.Element {
  const { t } = useTranslation('productDetails');
  const [selection, sizeSelectProps, inseamSelectProps, ...multiProductProps] =
    useMultiProductOptions(value, node, onOptionSelected);
  const showColors = Boolean(multiProductProps.length);

  useEffect(() => {
    onChange?.(selection);
  }, [selection]);

  const badgeNode = (() => {
    if (specialSale) {
      return (
        <Badge className={clsx(multiProductBadgeStyles)} label={t('sale')} />
      );
    }
    if (selection.coming_soon) {
      return (
        <Badge
          className={clsx(multiProductBadgeStyles)}
          label={t('comingSoon')}
        />
      );
    }
    if (selection.quantity === 0) {
      return (
        <Badge
          className={clsx(multiProductBadgeStyles)}
          color='gray'
          label={t('outOfStock')}
        />
      );
    }
    if (selection.pro_exclusive) {
      return (
        <Badge
          className={clsx(multiProductBadgeStyles)}
          color='pro'
          label={t('whoopProExclusive')}
        />
      );
    }
    if (selection.on_sale) {
      return (
        <Badge className={clsx(multiProductBadgeStyles)} label={t('onSale')} />
      );
    }
    if (selection.new) {
      return (
        <Badge className={clsx(multiProductBadgeStyles)} label={t('new')} />
      );
    }
  })();

  let onlyBlankLeftNode;
  if (selection.quantity && selection.quantity > 0 && selection.quantity < 10) {
    onlyBlankLeftNode = (
      <span className='text-blue-fewLeft font-thin'>
        {' - '}
        {t('onlyQuantityLeft', { quantity: selection.quantity })}
      </span>
    );
  }

  return (
    <div
      className={clsx(
        '[&>h2]:flex [&>h2]:flex-wrap [&>h2]:items-center [&>h2]:gap-2 [&>h2]:py-6 [&>h2]:text-2xl [&>h2]:tracking-normal',
        'first:[&_.selectedOptionInner]:!mt-0',
      )}
    >
      {showColors ||
      selection.color?.label ||
      onlyBlankLeftNode ||
      badgeNode ? (
        <h2>
          {showColors ? t('color') : undefined}
          <span className={selectedOptionOuterStyles}>
            {selection.color?.label}
            {onlyBlankLeftNode}
          </span>
          {badgeNode}
        </h2>
      ) : null}
      {multiProductProps.map((props, index) => (
        <div key={index}>
          {props?.label ? (
            <h4 className='!mb-2 !mt-6 text-lg font-semibold tracking-normal'>
              {props.label}
              {props.subLabel ? <span>: {props.subLabel}</span> : undefined}
            </h4>
          ) : undefined}
          <div className={swatchOptionsStyles}>
            <SwatchOptionSelect
              name={`multi-product-color-${index}`}
              showAllOptions
              {...props}
            />
          </div>
        </div>
      ))}
      {sizeSelectProps ? (
        <>
          <h2>
            {t('size')}
            <span className={selectedOptionOuterStyles}>
              {selection.size?.label}
            </span>
          </h2>
          <div className={clsx(swatchOptionsStyles)}>
            <SwatchOptionSelect
              className='w-full'
              name='size'
              showAllOptions
              {...sizeSelectProps}
            />
          </div>
        </>
      ) : undefined}
      {inseamSelectProps ? (
        <>
          <h2>
            {t('inseam')}
            <span className={selectedOptionOuterStyles}>
              {selection.inseam?.label}
            </span>
          </h2>
          <div className={swatchOptionsStyles}>
            <SwatchOptionSelect
              name='inseam'
              showAllOptions
              {...inseamSelectProps}
            />
          </div>
        </>
      ) : undefined}
    </div>
  );
}
