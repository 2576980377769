/* eslint-disable id-length */
'use client';

import type { HTMLAttributes } from 'react';
import { useState } from 'react';
import type { Currency } from '@whoop/i18n/types/internationalization';
import { useTranslation } from '@whoop/i18n/lang/client';
import clsx from 'clsx';
import type { Gender } from '../../../utils/legacyProductHelpers';
import type {
  MeasurementOption,
  MeasurementUnit,
} from '../../../utils/sizingGuideUtils';
import { getSizingData } from '../../../utils/sizingGuideUtils';
import CaretUp from '../../../icons/icons/Navigation/CaretUp';
import CaretDown from '../../../icons/icons/Navigation/CaretDown';
import { RadioToggle } from '../../RaddioToggle/RadioToggle';

const toggleStyles = 'flex w-full items-center justify-between p-0';
const hiddenStyles = 'h-0 overflow-hidden';
const visibleStyles = 'h-fit';
const headingStyles =
  'font-whoopSemibold m-0 flex flex-wrap items-center gap-2 py-3 tracking-normal text-black';
const h3Styles = 'text-lg capitalize';

function Cell({ values, units }): JSX.Element {
  const value = values[units] || '--';
  if (Array.isArray(value)) {
    if (value[0] === 0) {
      return <td>&lt; {value[1]}</td>;
    }
    if (value[1] === Infinity) {
      return <td>{value[0]} &gt;</td>;
    }
    return (
      <td>
        {value[0]}-{value[1]}
      </td>
    );
  }
  return <td>{value}</td>;
}

interface SizingGuideProps extends HTMLAttributes<HTMLDivElement> {
  currency: Currency;
  gender?: Gender;
  type: string;
}

export function SizingGuide({
  currency,
  gender = 'unisex',
  type = 'BICEP',
  className,
  ...props
}: SizingGuideProps): JSX.Element {
  const { t } = useTranslation('sizingGuide');
  const inOption: MeasurementOption = { value: 'in', label: t('inches') };
  const cmOption: MeasurementOption = { value: 'cm', label: t('centimeters') };
  const defaultUnit: MeasurementUnit = currency === 'usd' ? 'in' : 'cm';
  const options =
    defaultUnit === 'cm' ? [cmOption, inOption] : [inOption, cmOption];

  const [currentUnits, setCurrentUnits] =
    useState<MeasurementUnit>(defaultUnit);
  const [showGuide, setShowGuide] = useState<boolean>(false);
  const [showMeasurements, setShowMeasurements] = useState<boolean>(false);

  const { chart, guideImage, howToMeasure } = getSizingData(type);
  const chartCols = Object.keys(chart ?? {});
  const chartRows = Array.from(
    Object.values(chart ?? {})
      .map(Object.keys)
      .reduce((set, keys) => {
        keys.forEach(set.add, set);
        return set;
      }, new Set<string>()),
  );

  const toggleShowGuide = (): void => setShowGuide(!showGuide);
  const toggleShowMeasurements = (): void =>
    setShowMeasurements(!showMeasurements);

  return (
    <div
      className={clsx(
        className,
        'font-whoop border-gray-40 overflow-y-auto overflow-x-hidden border-b text-left',
        '[&_td]:border-0',
        'text-sm font-normal',
        '[&_table]:mx-auto [&_table]:border-collapse',
        '[&_table_tr]:border-b [&_table_tr]:border-black',
        '[&_table_th]:box-content [&_table_th]:px-1.5 [&_table_th]:py-0.5',
        '[&_table_td]:box-content [&_table_td]:px-1.5 [&_table_td]:py-0.5',
      )}
      {...props}
    >
      <div>
        <button
          className={toggleStyles}
          onClick={toggleShowGuide}
          type='button'
        >
          <h2 className={clsx(headingStyles, 'text-2xl')}>
            {t('sizingGuide')}
          </h2>
          {showGuide ? (
            <CaretUp
              fill='black'
              height={16}
              title='close sizing guide'
              width={16}
            />
          ) : (
            <CaretDown
              fill='black'
              height={16}
              title='open sizing guide'
              width={16}
            />
          )}
        </button>
      </div>
      <div className={clsx(showGuide ? visibleStyles : hiddenStyles)}>
        <p>{t('tapeMeasureText')}</p>
        {type === 'ARM' && <p className='pt-2'>{t('sizingRecommendation')}</p>}
        <h3 className={clsx(headingStyles, h3Styles)}>{gender}</h3>
        <div className={clsx('m-auto')}>
          <RadioToggle
            label='unit-type'
            onChange={setCurrentUnits}
            options={options}
            size='small'
            value={currentUnits}
          />
        </div>

        {chart ? (
          <div
            className={clsx(
              'flex transition-transform',
              currentUnits !== defaultUnit ? '-translate-x-full' : '',
            )}
          >
            {options.map(({ value }) => (
              <div
                className='min-w-full px-2.5 pb-6 pt-3.5 text-center'
                key={value}
              >
                <table>
                  <thead>
                    <tr>
                      <th />
                      {chartCols.map((col) => (
                        <th className='max-w-20' key={col}>
                          {t(`howToMeasureKeys.${col}`)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {chartRows.map((row: string) => (
                      <tr className='last:!border-b-0' key={row}>
                        <th className='max-w-20 uppercase'>{row}</th>
                        {chartCols.map((col) => (
                          <Cell
                            key={col}
                            units={currentUnits}
                            values={chart[col][row]}
                          />
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ) : null}
        {howToMeasure ? (
          <>
            <button
              className={clsx(toggleStyles, 'border-gray-40 border-t')}
              onClick={toggleShowMeasurements}
              type='button'
            >
              <h3 className={clsx(headingStyles, h3Styles)}>
                {t('howToMeasure')}
              </h3>
              {showMeasurements ? (
                <CaretUp
                  fill='black'
                  height={16}
                  title='close how to measure'
                  width={16}
                />
              ) : (
                <CaretDown
                  fill='black'
                  height={16}
                  title='open how to measure'
                  width={16}
                />
              )}
            </button>
            <div
              className={clsx(
                'flex flex-row flex-wrap items-center justify-center px-8',
                showMeasurements ? visibleStyles : hiddenStyles,
              )}
            >
              <table className='min-w-full max-w-lg flex-1 text-left'>
                <tbody>
                  {Object.keys(howToMeasure).map((key, i) => (
                    <tr className='last:!border-b-0' key={key}>
                      <th className='whitespace-nowrap text-left align-baseline'>
                        {i + 1} - {t(`howToMeasureKeys.${key}`)}
                      </th>
                      <td className='text-left align-baseline'>
                        {t(`howToMeasureValues.${howToMeasure[key]}`)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {guideImage ? (
                <img
                  alt='measurement instructions'
                  className='min-w-64 m-auto max-h-64 flex-1 object-contain pb-6 pt-3'
                  src={guideImage.src}
                />
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
